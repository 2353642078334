
import {Component, Vue} from 'vue-property-decorator';

// Config
import {RouteNames} from "@/config/router.config";
import {track, TrackingEventType} from "@/config/tracking.config";

// Components
import LoadingOverlay from '../components/loading-overlay.component.vue';
import PageHeader from '../components/page-header.component.vue';
import axios from "axios";

@Component({
  components: {
    LoadingOverlay,
    PageHeader
  }
})
export default class ContactsView extends Vue {
  loading = true;
  showOverlay = false;
  showError = false;

  name = '';
  email = '';
  phone = '';
  message = '';

  contactChannels = [
    {
      iconClass: 'fa fa-envelope',
      title: this.$t('Views.Contacts.EmailTitle'),
      description: this.$t('Views.Contacts.EmailDescription'),
      urlLabel: 'info@greenorbi.com',
      urlLink: 'mailto:info@greenorbi.com'
    }
  ]

  protected created() {
    track(TrackingEventType.ViewContent, {
      content_category: RouteNames.Contacts
    });
  }

  closeOverlay() {
    this.toggleOverlay(false);

    // Only reset form if message was sent successfully
    if (!this.showError) {
      this.name = '';
      this.email = '';
      this.phone = '';
      this.message = '';
    }

    // Wait for animation to run before resetting overlay
    setTimeout(() => {
      this.toggleLoading(true);
      this.toggleError(false);
    }, 500);
  }

  submit(event: Event) {
    event.preventDefault();
    this.toggleError(false);
    this.toggleOverlay(true);
    this.toggleLoading(true);

    const data = new FormData();
    data.set('form_type', 'contact');
    data.set('contact[Nome]', this.name);
    data.set('contact[email]', this.email);
    data.set('contact[Número de telefone]', this.phone);
    data.set('contact[Comentário]', this.message);

    axios.post('https://bellabyana.myshopify.com/contact', data)
        .catch(() => {
          this.toggleError(true);
        })
        .finally(() => {
          this.toggleLoading(false);
        });
  }

  toggleError(show?: boolean) {
    this.showError = typeof show === 'boolean' ? show : !this.showError;
  }

  toggleLoading(show?: boolean) {
    this.loading = typeof show === 'boolean' ? show : !this.loading;
  }

  toggleOverlay(show?: boolean) {
    this.showOverlay = typeof show === 'boolean' ? show : !this.showOverlay;
  }

  trackContact() {
    track(TrackingEventType.Contact);
  }
}
