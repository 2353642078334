
import { Component, Prop, Vue } from 'vue-property-decorator';

// Components
import LoadingSpinner from '@/components/loading-spinner.component.vue';

@Component({
  components: {
    LoadingSpinner
  }
})
export default class LoadingOverlay extends Vue {

  @Prop({
    type: Boolean,
    default: false
  })
  loading!: boolean;

  @Prop({
    type: Boolean,
    required: true
  })
  visible!: boolean;

  @Prop({
    type: String,
    default: ''
  })
  message!: string;

  @Prop({
    type: String,
    default: ''
  })
  buttonLabel!: string;

  handleButtonClick(): void {
    this.$emit('click');
  }
}
